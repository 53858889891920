import React from 'react';
import { Link } from "gatsby";
import banana from '../img/banana_error_404.png';
import styles from './error.module.scss'

const errorPage = () => {


    return (
        <>
            <div className={styles.errorBlock}>
                <div><img src={banana} width="auto" height="auto" alt="banana" /></div>
                <Link to='/' className={styles.integrationArrow}>
                    <span className={styles.inVisible}>Go to home page</span>
                </Link>
                <p>The page you are looking for was moved or might never existed.</p>
            </div>
        </>
    )
}

export default React.memo(errorPage);