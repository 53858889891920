import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import ErrorPage from '../components/404/404';

const Blog = () => (
 <>
  <ErrorPage />
 </>
)

export default Blog